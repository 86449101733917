import axios from "axios";
import { logout } from "./services/authenticationService";
import { toast } from 'react-toastify';
import { debounce } from "@material-ui/core";

axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = "Token " + token;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

var wasAlreadyWarning = false;
var wasAlreadyError = false;

axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    
    if(!!error.response){

      const { status } = error.response;
      if (status === 401) {
        // logout();
        // window.location("/");
        if(!wasAlreadyWarning) {
          wasAlreadyWarning = true;
          toast.warn("Brak uprawnień.");
          setTimeout(() => {wasAlreadyWarning = false}, 1000)
        }
        // return Promise.resolve({});
      }else if(status === 404) {
        toast.error(error.response.data.status || "Error!");
      }
       else {
        if(!wasAlreadyError) {
          wasAlreadyError = true;
          
          setTimeout(() => {wasAlreadyError = false}, 1000)
        } 
      }
    }
    return Promise.reject(error);
  }
);

import React, { useEffect, useState } from "react";
import {
  getAbsents,
  addAbsence,
  getAbsenceTypes,
  updateAbsence,
  deleteAbsence,
  getFreeDays,
} from "../../../services/calendarService";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import { defaultDateFormat, dateToDayMonthYear } from "../../../utils/dates";
import { getWorkers } from "../../../services/userService";
import AbsenceDialog from "./AbsenceDialog";
import moment from "moment";
import "moment/locale/pl";
import CalendarToolbar from "./CalendarToolbar";
import { toast } from "react-toastify";
import { getDay } from "date-fns";
import { Paper } from "@material-ui/core";

const localizer = momentLocalizer(moment);

function AbsenceCalendar() {
  const [date, setDate] = useState(new Date());
  const [events, setEvents] = useState([]);
  const [absenceTypes, setAbsenceTypes] = useState([]);
  const [openAbsenceDialog, setOpenAbsenceDialog] = useState(false);
  const [absenceData, setAbsenceData] = useState({});
  const [people, setPeople] = useState([]);
  const [freeDays, setFreeDays] = useState({});
  const holidays = Object.keys(freeDays);

  const fetchAbsents = async () => {
    try {
      const { month, year } = dateToDayMonthYear(date);
      const response = await getAbsents(month, year);
      const events = response.data.map((event) => ({
        id: event.id,
        title: event.user,
        start: moment(event.start_date).toDate(),
        end: moment(event.end_date).add(1, "hours").toDate(),
        allDay: true,
        userId: event.user_id,
        reason: event.reason,
        short_reason: event.short_reason,
        color: event.color,
        event_type: event.absence_type,
        comment: event.comment,
      }));
      setEvents(events);
    } catch (error) {
      console.log(error);
      toast.error("Błąd pobierania nieobecności");
    }
  };

  const fetchAbsenceTypes = async () => {
    try {
      const response = await getAbsenceTypes();
      setAbsenceTypes(response.data);
    } catch (error) {
      console.log(error);
      toast.error("Błąd pobierania typów nieobecności");
    }
  };

  const fetchPeople = async () => {
    try {
      const response = await getWorkers(true);
      const people = response.data;
      setPeople(people);
    } catch (error) {
      console.log(error);
      toast.error("Błąd pobierania pracowników");
    }
  };

  const fetchFreeDays = async () => {
    try {
      const response = await getFreeDays();
      setFreeDays(response.data);
    } catch (error) {
      console.log(error);
      toast.error("Błąd pobierania dni wolnych od pracy");
    }
  };

  const handleCloseAbsenceDialog = () => setOpenAbsenceDialog(false);

  useEffect(() => {
    fetchAbsenceTypes();
    fetchPeople();
    fetchFreeDays();
  }, []);

  useEffect(() => {
    fetchAbsents();
  }, [date]);

  const handleAddAbsenceClick = (e) => {
    setOpenAbsenceDialog(true);
    setAbsenceData({
      person: "",
      event_type: "",
      comment: "",
      start_date: defaultDateFormat(e.start),
      end_date: defaultDateFormat(e.end),
    });
  };

  const prepareAbsence = () => {
    const format = (date) => moment(date, "YYYY-MM-DD").format("YYYY-MM-DD");
    const start = format(absenceData.start_date);
    const end = format(absenceData.end_date);
    return {
      ...absenceData,
      start_date: start,
      end_date: end,
    };
  };

  const handleAddAbsence = async () => {
    try {
      const absenceToSend = prepareAbsence();
      await addAbsence(absenceToSend);
      resetAbsenceData();
      toast.success("Dodano nieobecność");
    } catch (error) {
      console.log(error);
      toast.error("Błąd dodawania nieobecności");
    }
  };

  const handleUpdateAbsence = async () => {
    try {
      const absenceToSend = prepareAbsence();
      await updateAbsence(absenceToSend);
      resetAbsenceData();
      toast.success("Zaktualizowano nieobecność");
    } catch (error) {
      console.log(error);
      toast.error("Błąd aktualizacji nieobecności");
    }
  };

  const isOverlappingOtherAbsence = () => {
    const { start_date, end_date, person, id } = absenceData;
    const userEvents = events.filter(
      (event) => event.userId === person && event.id !== id
    );
    const overlapWithStart = userEvents.some(
      (event) =>
        moment(start_date).isSameOrBefore(event.end) &&
        moment(start_date).isSameOrAfter(event.start)
    );
    const overlapWithEnd = userEvents.some(
      (event) =>
        moment(end_date).isSameOrBefore(event.end) &&
        moment(end_date).isSameOrAfter(event.start)
    );
    return overlapWithStart || overlapWithEnd;
  };

  const handleAddOrUpdateAbsence = async () => {
    if (isOverlappingOtherAbsence()) {
      return toast.warning("Data pokrywa się z istniejącym urlopem");
    }
    if (absenceData.id) {
      handleUpdateAbsence();
    } else {
      handleAddAbsence();
    }
  };

  const resetAbsenceData = () => {
    setAbsenceData({});
    setOpenAbsenceDialog(false);
    fetchAbsents();
  };

  const handleAbsenceDataChange = (e) => {
    const { name, value } = e.target;
    setAbsenceData({ ...absenceData, [name]: value });
  };

  const handleEventClick = (e) => {
    const { id } = e;
    const absence = events.find((event) => event.id === id);
    if (!absence) {
      return;
    }
    setAbsenceData({
      person: absence.userId,
      event_type: absence.event_type || "",
      comment: absence.comment,
      start_date: absence.start,
      end_date: absence.end,
      id,
    });
    setOpenAbsenceDialog(true);
  };

  const handleDeleteAbsence = async () => {
    try {
      await deleteAbsence(absenceData.id);
      resetAbsenceData();
      toast.success("Usunięto nieobecność");
    } catch (error) {
      console.log(error);
      // toast.error('Coś poszło nie tak.');
    }
  };

  const onNavigate = (newDate) => {
    setDate(newDate);
  };

  const customDayPropGetter = (date) => {
    const dayOfWeek = getDay(date);
    let background;
    if (dayOfWeek === 6 || dayOfWeek === 0) {
      background = "#9e9e9e99";
    }

    const formattedDate = defaultDateFormat(date);
    if (holidays.some((date) => date === formattedDate)) {
      background = "#df44448c";
    }
    return {
      style: {
        background,
      },
    };
  };

  const customEventPropGetter = (event) => {
    return {
      style: {
        background: event.color,
      },
    };
  };

  useEffect(() => {
    const showMoreBtns = document.querySelectorAll(".rbc-show-more");
    showMoreBtns.forEach((btn) => {
      btn.innerText = btn.innerText.replace("more", "więcej");
    });
  });

  return (
    <Paper square elevation={4} style={{ padding: 15 }}>
      <div style={{ height: "80vh" }}>
        <Calendar
          selectable
          localizer={localizer}
          events={events}
          defaultView={Views.MONTH}
          views={["month"]}
          defaultDate={new Date()}
          date={date}
          onSelectEvent={handleEventClick}
          onSelectSlot={handleAddAbsenceClick}
          components={{
            toolbar: CalendarToolbar,
          }}
          onNavigate={onNavigate}
          dayPropGetter={customDayPropGetter}
          eventPropGetter={customEventPropGetter}
          popup={true}
        />
        <AbsenceDialog
          open={openAbsenceDialog}
          handleClose={handleCloseAbsenceDialog}
          handleSave={handleAddOrUpdateAbsence}
          absenceData={absenceData}
          people={people}
          absenceTypes={absenceTypes}
          handleChange={handleAbsenceDataChange}
          handleDelete={handleDeleteAbsence}
        />
      </div>
    </Paper>
  );
}

export default AbsenceCalendar;

import React, { useEffect, useState } from "react";
import { getSubordinates } from "../../../services/worklogService";
import DefaultTable from "../../common/DefaultTable";
import Axios from "axios";
import { API } from "../../../constants";
import { dateToDayMonthYear, defaultDateFormat } from "../../../utils/dates";
import { Grid, TextField } from "@material-ui/core";
import WorklogToolbar from "./WorklogToolbar";
import { toast } from "react-toastify";
import Select from "../../common/Select";
import { getDepartments } from "../../../services/administrationService";
import { getProjects } from "../../../services/projectService";

const TimeInput = (props) => {
  const { value, onChange, updateWorklog, rowData } = props;
  const [isValid, setIsValid] = useState(true);

  const handleChange = (value, e) => {
    if (value.length === 2) {
      value = value + ":";
    }
    onChange(value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      return updateWorklog(rowData);
    }
  };

  const validateTime = (time) => {
    const isOk = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(time);
    if (!isOk) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  };

  const clearField = () => {
    onChange("");
  };

  return (
    <>
      <TextField
        value={value}
        onChange={(e) => handleChange(e.target.value, e)}
        onKeyDown={handleKeyDown}
        onBlur={() => validateTime(value)}
        error={!isValid}
        onFocus={clearField}
        autoFocus={props.autoFocus}
        helperText={
          !isValid && (
            <div>
              Nieprawidłowy format daty.
              <br /> Format to HH:MM
            </div>
          )
        }
      />
    </>
  );
};

function Worklog() {
  const [subordinates, setSubordinates] = useState([]);
  const [date, setDate] = useState(new Date());
  const [departments, setDepartments] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(undefined);
  const [selectedProject, setSelectedProject] = useState(undefined);

  const fetchSubordinates = async () => {
    try {
      const { day, month, year } = dateToDayMonthYear(date);
      const subordinates = await getSubordinates(
        day,
        month,
        year,
        selectedDepartment,
        selectedProject
      );
      const subordinatesWithShorterTime = subordinates.map((subordinate) => ({
        ...subordinate,
        time_in: subordinate.time_in.substring(0, 5),
        time_out: subordinate.time_out.substring(0, 5),
      }));
      setSubordinates(subordinatesWithShorterTime);
    } catch (error) {
      console.log(error);
      // toast.error('Coś poszło nie tak.');
    }
  };

  const updateWorklog = (data) =>
    new Promise((resolve, reject) => {
      const dateToSend = defaultDateFormat(date);
      data.date_of_event = dateToSend;
      data.person = data.workerId;
      data.project = data.projectId;

      if (data.time_in === "") {
        data.time_in = 0;
      }
      if (data.time_out === "") {
        data.time_out = 0;
      }

      Axios.post(`${API.EVENT}`, [data])
        .then((data) => {
          fetchSubordinates();
          toast.success("Zapisano godziny");
          resolve();
        })
        .catch((error) => {
          console.log(error);
          // toast.error('Coś poszło nie tak.');
          reject();
        });
    });

  const fetchDepartments = async () => {
    try {
      const departments = await getDepartments();
      setDepartments(departments);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProjects = async () => {
    try {
      const projects = await getProjects();
      setProjects(projects);
    } catch (error) {
      console.log(error);
    }
  };

  const onDepartmentSelect = (event) => {
    setSelectedProject(undefined);
    setSelectedDepartment(event.target.value);
  };

  const onProjectSelect = (event) => {
    setSelectedDepartment(undefined);
    setSelectedProject(event.target.value);
  };

  useEffect(() => {
    fetchDepartments();
    fetchProjects();
  }, []);

  useEffect(() => {
    fetchSubordinates();
  }, [date, selectedDepartment, selectedProject]);

  const columns = [
    {
      title: "Pracownik",
      field: "worker",
      editable: "never",
    },
    {
      title: "Oddział",
      field: "department",
      editable: "never",
    },
    {
      title: "Budowa",
      field: "project",
      editable: "never",
    },
    {
      title: "Numer karty",
      field: "card_number",
      editable: "never",
    },
    {
      title: "Godzina wejścia",
      field: "time_in",
      editComponent: (props) => (
        <TimeInput {...props} autoFocus updateWorklog={updateWorklog} />
      ),
    },
    {
      title: "Godzina wyjścia",
      field: "time_out",
      editComponent: (props) => (
        <TimeInput {...props} updateWorklog={updateWorklog} />
      ),
    },
  ];

  return (
    <Grid container direction="column" spacing={3}>
      <WorklogToolbar handleDateChange={setDate} date={date} />
      <Grid container style={{ padding: 12 }}>
        <Grid item xs={4}>
          <Select
            onChange={onDepartmentSelect}
            label="Dział"
            value={selectedDepartment || ""}
            items={departments}
            itemLabelProp="department"
            itemValueProp="id"
          />
        </Grid>
        <Grid item xs={4} style={{ paddingLeft: 20 }}>
          <Select
            onChange={onProjectSelect}
            label="Budowa"
            value={selectedProject || ""}
            items={projects}
            itemLabelProp="project"
            itemValueProp="id"
          />
        </Grid>
      </Grid>
      <Grid item>
        <DefaultTable
          columns={columns}
          data={subordinates}
          editable={{
            onRowUpdate: (newData, oldData) => updateWorklog(newData),
          }}
        />
      </Grid>
    </Grid>
  );
}

export default Worklog;

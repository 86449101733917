import axios from "axios";
import { API } from "../constants";
import { toast } from "react-toastify";

export const getReaders = async () => {
  try {
    const response = await axios.get(API.READERS);
    return response.data;
  } catch (error) {
    console.log(error);
    // toast.error('Coś poszło nie tak.');
    throw error;
  }
};

export const addReaders = async (reader_name, reader_ip )=> {
    try {
      const response = await axios.post(API.READERS, {
          reader_name:reader_name, 
            reader_ip: reader_ip});
      return response.data;
    } catch (error) {
      console.log(error);
      // toast.error('Coś poszło nie tak.');
      throw error;
    }
  };
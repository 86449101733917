import { Dialog, DialogTitle, DialogContent, Grid, TextField, DialogActions, Button } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { INVOICE_STAGE } from '../../common/invoiceStages';
import { changeInvoiceStage } from "../../../services/invoiceService";

function RejectInvoiceDialog({ open, handleClose, deleteInvoiceData }) {

	const INVOICE_TYPE = 'purchase';
	const [deleteInvoiceInternalData, setDeleteInvoiceInternalData] = useState({})

	useEffect(() => {
		setDeleteInvoiceInternalData({...deleteInvoiceData, ...{comment: ''}});
	}, [deleteInvoiceData])

	const internalHandleClose = () => {
		handleClose();
	}

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setDeleteInvoiceInternalData({ ...deleteInvoiceData, [name]: value });
	};

	const internalHandleConfirm = async () => {
		await changeInvoiceStage(deleteInvoiceInternalData.id, {
			stage: INVOICE_STAGE.REJECTED,
			invoice: deleteInvoiceInternalData.invoice,
			price: deleteInvoiceInternalData.price,
			comment: deleteInvoiceInternalData.comment
		}, INVOICE_TYPE);
		handleClose();
	}

	return (
		<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">Dodaj komentarz do odrzucenia.</DialogTitle>
			<DialogContent>
				<TextField
					id="comment"
					label="Komentarz"
					name="comment"
					value={deleteInvoiceInternalData.comment || ''}
					fullWidth
					onChange={handleInputChange}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => internalHandleClose()} color="primary">
					Anuluj
				</Button>
				<Button onClick={() => internalHandleConfirm()} color="primary">
					Odrzuć
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default RejectInvoiceDialog;

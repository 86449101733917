import axios from "axios";
import { API } from "../constants";
import { toast } from "react-toastify";

export const getProjects = async () => {
  try {
    const response = await axios.get(API.PROJECT);
    return response.data;
  } catch (error) {
    console.log(error);
    // toast.error('Coś poszło nie tak.');
    throw error;
  }
};

export const addProject = async project => {
  return axios.post(API.PROJECT, { project });
};

export const updateProject = async project => {
  return axios.put(`${API.PROJECT}id/${project.id}/`, project);
};

export const deleteProject = async project => {
  return axios.post(`${API.PROJECT}delete/${project.id}/`);
};

export const getAssignableProjects = () =>
  axios.get(`${API.PROJECT}onpermission/`);

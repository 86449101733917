import axios from "axios";
import { API } from "../constants";

const labelToProp = {
  Pracownik: "worker",
  Budowa: "project",
  Oddział: "department",
  "Godzina wejścia": "time_in",
  "Godzina wyjścia": "time_out",
  "Numer karty": "card_number",
};

export const getSubordinates = async (
  day,
  month,
  year,
  departmentId = 0,
  projectId = 0
) => {
  const response = await axios.get(
    `${API.WORKLOG}${day}/month/${month}/year/${year}/department/${departmentId}/project/${projectId}/`
  );
  const multiArrays = response.data;
  const transformed = multiArrays
    .map((array, mainIndex) =>
      array.map((sub, subIndex) => ({
        [labelToProp[multiArrays[mainIndex][subIndex].label]]:
          multiArrays[mainIndex][subIndex].value,
        [`${[labelToProp[multiArrays[mainIndex][subIndex].label]]}Id`]:
          multiArrays[mainIndex][subIndex].id,
      }))
    )
    .map((subArray) =>
      subArray.reduce(function (result, current) {
        return Object.assign(result, current);
      }, {})
    );

  return transformed;
};

import { API } from "../constants";
import axios from "axios";
import { getToken } from "./authenticationService";
import { toast } from "react-toastify";

const transformEventsResponse = (response) => {
  const events = Object.values(response.data);
  const days = Object.values(events.pop());
  return { events, days };
};

export const getMonthEventsForAll = async (
  month,
  year,
  departmentId,
  projectId
) => {
  try {
    const response = await getEvents(
      0,
      month,
      year,
      0,
      departmentId,
      projectId
    );
    const { events, days } = transformEventsResponse(response);
    return { events, days };
  } catch (error) {
    console.log(error);
    // toast.error('Coś poszło nie tak.');
    throw error;
  }
};

export const getMonthEventsForEmployee = async (month, year, id) => {
  try {
    const response = await getEvents(0, month, year, id);
    const { events, days } = transformEventsResponse(response);
    return { events, days };
  } catch (error) {
    console.log(error);
    // toast.error('Coś poszło nie tak.');
    throw error;
  }
};

export const getWeekEventsForAll = async (
  day,
  month,
  year,
  departmentId,
  projectId
) => {
  try {
    const response = await getEvents(
      day,
      month,
      year,
      0,
      departmentId,
      projectId
    );
    const { events, days } = transformEventsResponse(response);
    return { events, days };
  } catch (error) {
    console.log(error);
    // toast.error('Coś poszło nie tak.');
    throw error;
  }
};

export const getRangeEventsForAll = async (
  dayFrom,
  monthFrom,
  yearFrom,
  dayTo,
  monthTo,
  yearTo,
  employeeId,
  departmentId,
  projectId
) => {
  try {
    const response = await getRangeEvents(
      dayFrom,
      monthFrom,
      yearFrom,
      dayTo,
      monthTo,
      yearTo,
      employeeId,
      departmentId,
      projectId
    );
    const { events, days } = transformEventsResponse(response);
    return { events, days };
  } catch (error) {
    console.log(error);
    // toast.error('Coś poszło nie tak.');
    throw error;
  }
};

const getRangeEvents = (
  dayFrom,
  monthFrom,
  yearFrom,
  dayTo,
  monthTo,
  yearTo,
  employeeId = 0,
  departmentId = 0,
  projectId = 0
) => {
  return axios.get(
    `${API.EVENT}day/${dayFrom}/month/${monthFrom}/year/${yearFrom}/endday/${dayTo}/endmonth/${monthTo}/endyear/${yearTo}/id/${employeeId}/department/${departmentId}/project/${projectId}/`
  );
};

const getEvents = (
  day,
  month,
  year,
  employeeId,
  departmentId = 0,
  projectId = 0
) => {
  return axios.get(
    `${API.EVENT}day/${day}/month/${month}/year/${year}/id/${employeeId}/department/${departmentId}/project/${projectId}/`
  );
};

export const getDownloadUrl = (
  day,
  month,
  year,
  employeeId = 0,
  departmentId = 0,
  projectId = 0
) =>
  `${
    API.EVENT
  }day/${day}/month/${month}/year/${year}/id/${employeeId}/department/${departmentId}/project/${projectId}/download/?token=${getToken()}`;

export const getRangeDownloadUrl = (
  dayFrom,
  monthFrom,
  yearFrom,
  dayTo,
  monthTo,
  yearTo,
  employeeId = 0,
  departmentId = 0,
  projectId = 0
) =>
  `${
    API.EVENT
  }day/${dayFrom}/month/${monthFrom}/year/${yearFrom}/endday/${dayTo}/endmonth/${monthTo}/endyear/${yearTo}/id/${employeeId}/department/${departmentId}/project/${projectId}/download/?token=${getToken()}`;

export const downloadXls = (url) => {
  try {
    axios({
      url,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `arkusz.xlsx`); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

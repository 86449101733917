import React from 'react'
import { Grid } from '@material-ui/core';

export const defaultColumns = [
    {
        title: 'Nazwa',
        field: 'name'
    },
    {
        title: 'Akcje',
        field: 'id'
    }
];

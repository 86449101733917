import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Grid, List, ListItem } from "@material-ui/core";
import { getWorkersInProject } from "../../../services/userService";
import Select from "../../common/Select";
import { Controller } from "react-hook-form";

const userData = {
  username: "",
  password: "",
  role: "",
};

export default function AddUserDialog({
  open,
  handleClose,
  projectName,
  projectId,
}) {
  const [workersInProject, setWorkersInProject] = useState([]);

  useEffect(() => {
    getWorkersInProject(projectId).then((workers) => {
      setWorkersInProject(workers.data);
    });
  }, [projectId]);

  if (!open) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Pracownicy - {projectName}
      </DialogTitle>
      <DialogContent style={{ overflowY: "hidden" }}>
        <Grid container justify="space-between" direction="column" spacing={3}>
          <List>
            {workersInProject.length
              ? workersInProject.map((worker) => (
                  <ListItem key={worker.id}>
                    {worker.first_name} {worker.last_name}
                  </ListItem>
                ))
              : null}
          </List>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Zamknij
        </Button>
      </DialogActions>
    </Dialog>
  );
}

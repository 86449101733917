export const TILES = {
	ALL: 'invoice/',
	UNASSIGNED: 'unassigned/',
	ACCEPTED: 'accepted/',
	REJECTED: 'rejected/',
	FILE_LIST: 'invoicefile/',
	PENDING: 'pending/',
	ACCOUNTING: 'accounting/accepted',
	ARCHIVE: 'accounting/all',
	HISTORY: 'history/',
	UNPAID: 'unpaid/'
};

export const BUDGET_TILES = {
	CURRENT: null,
	ARCHIVED: 'archived/',
};

export const FILE_TYPE = {
	INVOICE: "invoice",
	ATTACHMENT: "attachment"
}
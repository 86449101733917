import React, { useEffect, useState } from "react";
import {
  getMonthTimeStats,
  getTimeDepartmentsStats,
  getTimeProjectsStats,
  getEmployeesNumber,
  getHighestLowestTime
} from "../../../services/statisticsService";
import { Grid, Typography, Paper } from "@material-ui/core";
import DepartmentProjectTimeChart from "./DepartmentProjectTimeChart";
import { toast } from "react-toastify";
import Person from "../../../static/images/person.png";
import DatePicker from "../../common/DatePicker";
import { dateToDayMonthYear } from "../../../utils/dates";
import { format } from "date-fns";
import plLocale from "date-fns/locale/pl";
import LandscapeDatePicker from "../../common/LandscapeDatePicker";

const backgroundColors = [
  "#A4D5DB",
  "#8DCBD2",
  "#77C0C9",
  "#60B6C0",
  "#789DA2",
  "#90DDF0",
  "#9AE0F1",
  "#1C97A5",
  "#A4D5DB",
  "#8DCBD2",
  "#77C0C9",
  "#60B6C0",
  "#789DA2",
  "#90DDF0",
  "#9AE0F1",
  "#1C97A5",
  "#A4D5DB",
  "#8DCBD2",
  "#77C0C9",
  "#60B6C0",
  "#789DA2",
  "#90DDF0",
  "#9AE0F1",
  "#1C97A5",
  "#A4D5DB",
  "#8DCBD2",
  "#77C0C9",
  "#60B6C0",
  "#789DA2",
  "#90DDF0",
  "#9AE0F1",
  "#1C97A5",
  "#A4D5DB",
  "#8DCBD2",
  "#77C0C9",
  "#60B6C0",
  "#789DA2",
  "#90DDF0",
  "#9AE0F1",
  "#1C97A5",
  "#A4D5DB",
  "#8DCBD2",
  "#77C0C9",
  "#60B6C0",
  "#789DA2",
  "#90DDF0",
  "#9AE0F1",
  "#1C97A5"
];

function Statistics() {
  const [monthTime, setMonthTime] = useState([]);
  const [timeDepartment, setTimeDepartment] = useState([]);
  const [timeProjects, setTimeProjects] = useState([]);
  const [employeesNumber, setEmployeesNumber] = useState({
    departments: [],
    projects: []
  });
  const [workersTimes, setWorkersTimes] = useState({});

  const [date, setDate] = useState(new Date());
  const { month, year } = dateToDayMonthYear(date);

  const fetchMonhTime = async () => {
    try {
      const response = await getMonthTimeStats(month, year);
      setMonthTime(response.data);
    } catch (error) {
      console.log(error);
      // toast.error('Coś poszło nie tak.');
    }
  };

  const fetchTimeDepartment = async () => {
    try {
      const response = await getTimeDepartmentsStats(month, year);
      const data = response.data.map(el => ({
        department: el.department,
        total_time: Number(el.total_time / 3600).toFixed(1),
        total_overtime: Number(el.total_overtime / 3600).toFixed(1)
      }));
      setTimeDepartment(data);
    } catch (error) {
      console.log(error);
      // toast.error('Coś poszło nie tak.');
    }
  };

  const fetchTimeProjects = async () => {
    try {
      const response = await getTimeProjectsStats(month, year);
      const data = response.data.map(el => ({
        project: el.project,
        total_time: Number(el.total_time / 3600).toFixed(1),
        total_overtime: Number(el.total_overtime / 3600).toFixed(1)
      }));
      setTimeProjects(data);
    } catch (error) {
      console.log(error);
      // toast.error('Coś poszło nie tak.');
    }
  };

  const fetchEmployeesNumber = async () => {
    try {
      const response = await getEmployeesNumber();
      const [departmentsAndProjects] = response.data;
      setEmployeesNumber(departmentsAndProjects);
    } catch (error) {
      console.log(error);
      // toast.error('Coś poszło nie tak.');
    }
  };

  const fetchHighestLowestTime = async () => {
    try {
      const response = await getHighestLowestTime(month, year);
      setWorkersTimes(response.data[0]);
    } catch (error) {
      console.log(error);
      // toast.error('Coś poszło nie tak.');
    }
  };

  useEffect(() => {
    fetchMonhTime();
    fetchTimeDepartment();
    fetchTimeProjects();
    fetchEmployeesNumber();
    fetchHighestLowestTime();
  }, [month, year]);

  const totalMonthTime = monthTime
    .map(el => Object.values(el)[0])
    .reduce((prev, curr) => Number(prev) + Number(curr), 0);

  const secondsToFormatedTime = seconds => {
    let hours = Math.floor(seconds / 3600);
    let minutes = Number((seconds % 3600) / 60).toFixed(0);
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (hours < 10) {
      hours = "0" + hours;
    }
    return `${hours}:${minutes}`;
  };

  const onDateChange = date => {
    setDate(date);
  };

  return (
    <Grid
      container
      direction="column"
      spacing={4}
      style={{ overflow: "hidden" }}
    >
      <Grid item>
        <Paper square style={{ padding: 20 }}>
          <Grid container justify="space-between">
            <Grid item>
              Wybrany miesiąc: {format(date, "LLLL y", { locale: plLocale })}
            </Grid>
            <Grid item>
              <DatePicker
                views={["month", "year"]}
                value={date}
                onChange={onDateChange}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item>
        <Paper square style={{ padding: 20 }}>
          <Typography variant="h5" align="center" style={{ marginBottom: 30 }}>
            Ilość przepracowanych godzin w miesiącu
          </Typography>
          <Typography variant="h6" align="center">
            {Number(totalMonthTime).toFixed(2)}
          </Typography>
        </Paper>
      </Grid>
      <Grid item>
        <Paper square style={{ height: 400, padding: 20 }}>
          <Typography variant="h5" align="center" style={{ marginBottom: 30 }}>
            Ilość godzin i nadgodzin dla działów
          </Typography>
          <DepartmentProjectTimeChart
            data={timeDepartment}
            indexBy="department"
          />
        </Paper>
      </Grid>
      <Grid item>
        <Paper square style={{ height: 400, padding: 20 }}>
          <Typography variant="h5" align="center" style={{ marginBottom: 30 }}>
            Ilość godzin i nadgodzin w projektach
          </Typography>
          <DepartmentProjectTimeChart data={timeProjects} indexBy="project" />
        </Paper>
      </Grid>
      <Grid item>
        <Paper square style={{ padding: 20 }}>
          <Typography variant="h5" align="center" style={{ marginBottom: 30 }}>
            Ilość pracowników w działach
          </Typography>
          <Grid container spacing={3}>
            {employeesNumber.departments.map((department, index) => (
              <Grid item key={department.department} xs={2}>
                <Paper
                  style={{
                    padding: 15,
                    textAlign: "center",
                    background: backgroundColors[index]
                  }}
                  square
                  elevation={4}
                >
                  <Typography>{department.department}</Typography>
                  <Typography style={{ fontSize: "2em" }}>
                    {department.workers_count}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Grid>
      <Grid item>
        <Paper square style={{ padding: 20 }}>
          <Typography variant="h5" align="center" style={{ marginBottom: 30 }}>
            Ilość pracowników w projektach
          </Typography>
          <Grid container spacing={3}>
            {employeesNumber.projects.map((project, index) => (
              <Grid item key={project.project} xs={2}>
                <Paper
                  style={{
                    padding: 15,
                    textAlign: "center",
                    background: backgroundColors[index]
                  }}
                  square
                  elevation={4}
                >
                  <Typography>{project.project}</Typography>
                  <Typography style={{ fontSize: "2em" }}>
                    {project.workers_count}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Grid>
      <Grid item container justify="space-around" spacing={2}>
        {[
          {
            worker: workersTimes.most_efficient,
            time: workersTimes.highest_time,
            label: "Pracownik z największą liczbą godzin"
          },
          {
            worker: workersTimes.most_overtime_worker,
            time: workersTimes.highest_overtime,
            label: "Pracownik z największą liczbą nadgodzin"
          },
          {
            worker: workersTimes.least_efficient,
            time: workersTimes.lowest_time,
            label: "Pracownik z najmniejszą liczbą godzin"
          }
        ].map(el => (
          <Paper square style={{ padding: 15 }}>
            <Grid
              container
              direction="column"
              style={{ width: 360 }}
              alignItems="center"
            >
              <Grid item>
                <Typography style={{ marginBottom: 10, whiteSpace: "nowrap" }}>
                  {el.label}
                </Typography>
              </Grid>
              <Grid item style={{ width: 280 }}>
                <Paper square style={{}}>
                  <Grid container direction="column" alignItems="center">
                    <Grid item>
                      <img
                        src={Person}
                        alt="person image"
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          marginBottom: 10
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="subtitle2"
                        style={{ textAlign: "center", fontSize: "1.3em" }}
                      >
                        {el.worker}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        style={{ textAlign: "center", fontSize: "2em" }}
                      >
                        {secondsToFormatedTime(el.time)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Paper>
        ))}
      </Grid>
    </Grid>
  );
}

export default Statistics;

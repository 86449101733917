import React, { useState, createRef, useEffect } from 'react';
import { Paper, Grid, TextField, Typography, Button, IconButton, InputAdornment } from '@material-ui/core';
import { PieChart } from 'react-minimal-pie-chart';
import ProgressBarCustom from './ProgressBarCustom';
import { useHistory, Link, useParams, useLocation } from 'react-router-dom';
import ConfirmInvoiceDialog from '../Invoices/ConfirmInvoiceDialog';
import UpdateIcon from '@material-ui/icons/Update';import Tile from '../../common/Tile';
import {uploadNewBudgetFile, updateBudgetName} from "../../../services/budgetService";
import {toast} from "react-toastify";
import { json2array } from '../../../utils/json2array';
import { getBudgetDetails, deleteBudget, downloadBudgetFile, archiveBudget, updateBudgetManager } from '../../../services/budgetService';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import GenericDialog from '../../common/GenericDialog';
import GetAppIcon from '@material-ui/icons/GetApp';
import { API } from '../../../constants';
import ReactTooltip from 'react-tooltip';
import {Chart} from "chart.js"
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ArchiveIcon from '@material-ui/icons/Archive';
import CustomTextFieldEdit from "../../common/CustomTextFieldEdit";
import Select from '../../common/Select';
import { getManagers } from '../../../services/userService';
import { validateFileExtension } from "../../../utils/FileValidatior";
import CustomLoader from "../../common/CustomLoader";
import Divider from "@material-ui/core/Divider";

function BudgetOverview() {
	const history = useHistory();
	let params = useParams();
	const location = useLocation();  

	const [isSelectFileDialogOpen, setSelectFileDialogOpen] = useState(false);
	const [budgetFile, setBudgetFile] = useState(null);
	
	const { budgetId } = params;
	const fileInput = createRef();


	const colorList = [
		"#eefdf6", "#cdfae4", "#acf7d1", "#8af4bf", "#69f1ad", "#48ee9a", "#26eb88", "#14d976", "#11b764", "#0e9652", "#0b753f", "#08532d", "#05321b", "#021109"
	]

	const handleSelectFileDialogClose = () => {
		setBudgetFile(null);
		setSelectFileDialogOpen(false);
	}

	const uploadFile = async () => {
		try {
			if(!validateFileExtension(budgetFile.name, ["xls", "xlsx"])){
				setBudgetFile(null);
				setSelectFileDialogOpen(false);	
				toast.error("Dozwolone tylko pliki .xls i .xlsx!");
				return;
			};
			setIsLoaded(false);
			let fileForm = new FormData();
			fileForm.append('file', budgetFile);
			fileForm.append('name', budgetFile.name);
			
			const budgetID = getBudgetIDFromPathname();
			if(budgetID){
				await uploadNewBudgetFile(budgetID, fileForm);
			} else {
				toast.error("Nie znaleziono ID budżetu.")
			}
			setBudgetFile(null);
			setSelectFileDialogOpen(false);
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoaded(true);
		}
	};

	const getBudgetIDFromPathname = () => {
		const budgetID = parseInt(location.pathname.split('/').pop());
		return isNaN(budgetID) ? null : budgetID;
	}

	const fileChange = (event) => {
		setBudgetFile(event.target.files[0]);

		if (!!event.target.files[0]) {
			setSelectFileDialogOpen(true);
		}
	};

	const [ budgetDetails, setBudgetDetails ] = useState([]);
	const [ budgetBasicData, setBudgetBasicData ] = useState({});
	const [ multiPlotValues, setMultiPlotValues ] = useState([]);
	const [ wheelValues, setWheelValues ] = useState([]);
	const [tilesData, setTilesData] = useState({})
	const [openDeleteBudgetDialog, setOpenDeleteBudgetDialog] = useState(false);
	const [openArchiveBudgetDialog, setOpenArchiveBudgetDialog] = useState(false);
	const [managers, setManagers] = useState({});
	const [newManager, setNewManager] = useState(null);
	const [isLoaded, setIsLoaded] = useState(true);

	const fetchBudgetDetails = async () => {
		try {
			setIsLoaded(false);
			const response = await getBudgetDetails(budgetId);
			const fetchedBudgetDetails = response.data;
			const arrayBudgetCategories = json2array(fetchedBudgetDetails.categories);
			
			const summary = arrayBudgetCategories.shift();
			const mainCategories = arrayBudgetCategories.filter(category => category.short_name[0].length == 2);
			setMultiPlotValues(arrayBudgetCategories)


			const wheelValuesFiltered = arrayBudgetCategories.filter(x => x.state.costs_amount !== null && parseFloat(x.state.costs_amount) > 0).map((x, idx) => 
				(
					{
						title: x.name[0], 
						value: parseFloat(x.state.costs_amount),
						color: colorList[idx]
					}
				));
			
			setWheelValues(wheelValuesFiltered);
			setBudgetDetails(arrayBudgetCategories);
			setBudgetBasicData(fetchedBudgetDetails.budget);
			setOldName(fetchedBudgetDetails.budget.name);
		} catch (error) {
			console.log(error);
			// toast.error('Coś poszło nie tak.');
		} finally {
			setIsLoaded(true);
		}
	};

	const fetchManagers = async () => {
		try {
			setIsLoaded(false);
			const response = await getManagers();
			setManagers(response.data);
		} catch (error) {
			console.log(error);
			toast.error('Błąd pobierania listy kierowników');
		} finally {
			setIsLoaded(true);
		}
	};


	useEffect(() => {
		fetchBudgetDetails();
		fetchManagers();
	}, []);

	// useEffect(() => {
	// 	if (wheelValues === null) return;
	// 	const ctx = document.getElementById('pie-chart').getContext('2d');
	// 	const myChart = new Chart(ctx, {
	// 		type: 'pie',
	// 		data: {
	// 			labels: wheelValues.map((value) => value.title),
	// 			datasets: [{
	// 				label: '# of Votes',
	// 				data: wheelValues.map((value) => value.value),
	// 				backgroundColor: wheelValues.map((value) => value.color),
	// 				borderWidth: 2
	// 			}]
	// 		},
	// 		options: {
	// 			legend: {
	// 				display: wheelValues.length <= 5
	// 			}
	// 		}
	// 	});
	//
	// }, [wheelValues]);

	const numberWithSpaces = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

	const handleCloseDeleteBudget = () => {
		setOpenDeleteBudgetDialog(false);
	}

	const handleSubmitDeleteBudget = async () => {
		const budgetID = getBudgetIDFromPathname();
		await deleteBudget(budgetID);
		setOpenDeleteBudgetDialog(false);
		history.push('/budzety');
	}

	const downloadExcelFile = async () => {
		const budgetID = getBudgetIDFromPathname();
		downloadBudgetFile(budgetID, budgetBasicData.name);		  
	}

	const [editName, setEditName] = useState(false);
	const [editContractMenager, setEditContractMenager] = useState(false);

	const handleEditName = () => {
		setEditName(true);

	}
	const [oldName, setOldName] = useState('');

	const handleAcceptNewName = async () => {
		await updateBudgetName(budgetBasicData.id, {name: budgetBasicData.name})
		setEditName(false);
		setOldName(budgetBasicData.name);
	}

	const handleRejectNewName = () => {
		setEditName(false);
		setBudgetBasicData({...budgetBasicData, ['name']: oldName});
	}


	const handleChange = (event) => {
		const {value, name} = event.target;
		setBudgetBasicData({...budgetBasicData, [name]: value});
	}

	const handleCloseArchiveBudget = async () => {
		await setOpenArchiveBudgetDialog(false);
	}

	const handleSubmitArchiveBudget = async () => {
		const budgetID = getBudgetIDFromPathname();
		await archiveBudget(budgetID);
		await setOpenArchiveBudgetDialog(false);
	}

	const handleOpenContractMenagerDialog = () => {
		setEditContractMenager(true);
	}

	const handleEditContractMenager = (event) => {
		const {value} = event.target;		
		setNewManager(value);
	}

	const handleAcceptNewContractMenager = async () => {
		const budgetID = getBudgetIDFromPathname();
		try {
			await updateBudgetManager(budgetID, {manager: newManager});
			await fetchBudgetDetails();
			toast.success("Zmiana kierownika.")
		} catch (error) {
			toast.error("Nie udało się zmienić kierownika.")
		} 
		setEditContractMenager(false);
		setNewManager(null);
	}

	const handleRejectNewContractMenager = () => {
		setNewManager(null);
		setEditContractMenager(false);

	}

	return (
		<Grid container spacing={2} direction={'column'}>
			<CustomLoader loaded={isLoaded}/>
			<Grid item spacing={10}>
				<Paper square style={{ padding: 25 }}>
				<Grid container justify={'flex-start'} alignItems={'center'} spacing={3}>
					<Grid item xs={2} sm={3} lg={2} xl={2}>
						<label htmlFor="upload-file">
							<input
								ref={fileInput}
								style={{ display: 'none' }}
								id="upload-file"
								name="upload-file"
								type="file"
								onChange={fileChange}
								/>
						<Tile icon={<UpdateIcon style={{ fontSize: '3em' }} />} text={'Aktualizuj'} style={{'min-width': 'fit-content'}} />
						<ConfirmInvoiceDialog
							open={isSelectFileDialogOpen}
							handleClose={handleSelectFileDialogClose}
							fileName={budgetFile || ''}
							saveDialog={uploadFile}
							/>
						</label>
					</Grid>
					<Grid item xs={2} sm={3} lg={2} xl={2}>
						<Tile icon={<DeleteForeverIcon style={{ fontSize: '3em' }} />} text={'Usuń'} onClick={() => setOpenDeleteBudgetDialog(true)} style={{'min-width': 'fit-content'}}/>
						<GenericDialog
							open={openDeleteBudgetDialog}
							handleClose={handleCloseDeleteBudget}
							handleConfirm={handleSubmitDeleteBudget}
							title={"Usunąć budżet?"}
							content={`Potwierdź, czy na pewno chcesz usunąć budżet ${budgetBasicData.name || ''}.`}
							confirmButtonText={"Usuń"}
						/>
					</Grid>
					<Grid item xs={2} sm={3} lg={2} xl={2}>
						<Tile icon={<ArchiveIcon style={{ fontSize: '3em' }} />} text={'Archiwizuj'} onClick={() => setOpenArchiveBudgetDialog(true)} style={{'min-width': 'fit-content'}}/>
						<GenericDialog
							open={openArchiveBudgetDialog}
							handleClose={handleCloseArchiveBudget}
							handleConfirm={handleSubmitArchiveBudget}
							title={"Archiwizować budżet?"}
							content={`Potwierdź, czy na pewno chcesz archiwizować budżet ${budgetBasicData.name || ''}.`}
							confirmButtonText={"Archiwizuj"}
						/>
					</Grid>
					<Grid item xs={2} sm={3} lg={2} xl={2}>
							<Tile icon={<GetAppIcon style={{ fontSize: '3em' }} />} text={'Pobierz arkusz'} onClick={downloadExcelFile} style={{'min-width': 'fit-content'}}/>
					</Grid>

				</Grid>
					<Grid container spacing={2}>
						<Grid item>

						</Grid>

					</Grid>
				<Grid container justify={'flex-start'} spacing={4} alignItems={'center'}>
						<Grid container direction={'column'} item xs={4}>
							<Grid item>
								<TextField
									id="standard-read-only-input1"
									label="Budżet"
									value={budgetBasicData.name || ''}
									name="name"
									onChange={handleChange}
									InputProps={{
										readOnly: !editName,
										endAdornment :(
											<CustomTextFieldEdit handleAccept={handleAcceptNewName} handleEdit={handleEditName} handleReject={handleRejectNewName} isEdit={editName}></CustomTextFieldEdit>
										  )

									}}
									fullWidth={true}

								/>
							</Grid>
							<Grid item>
								<TextField
									id="standard-read-only-input2"
									label="Budowa"
									value={budgetBasicData.project || ''}
									name="project"
									InputProps={{
										readOnly: true
									}}
									fullWidth={true}
								/>
							</Grid>
							<Grid item>
								<TextField
									id="standard-read-only-input3"
									label="Kierownik budowy"
									value={budgetBasicData.project_manager || ''}
									name="project_manager"
									InputProps={{
										readOnly: true
									}}
									fullWidth={true}
								/>
							</Grid>
							<Grid item>
								<TextField
									id="standard-read-only-input4"
									label="Kierownik kontraktu"
									value={budgetBasicData.manager || ''}
									InputProps={{
										readOnly: true,
										endAdornment :(
											<InputAdornment position="end">
												<IconButton
												aria-label="toggle-edit"
												edge="end"
												>
													<EditIcon
													onClick={() => handleOpenContractMenagerDialog()}
													/>
												</IconButton>
												<GenericDialog
													open={editContractMenager}
													handleClose={handleRejectNewContractMenager}
													handleConfirm={handleAcceptNewContractMenager}
													title={"Ustaw nowego kierownika kontraktu."}
													content={
														<Grid>
															{ managers && managers.length > 0 ?
															<Select
															onChange={handleEditContractMenager}
															label="Kierownik kontraktu"
															value={newManager || ''}
															name={'manager'}
															items={managers}
															itemValueProp="worker_id"
															customLabel={(item) => `${item.first_name} ${item.last_name}`}
															/>
															:
															<div>
																Brak menadżerów
															</div>
															}

														</Grid>
													}
													confirmButtonText={"Zapisz"}
												/>

											</InputAdornment>
											)
									}}
									fullWidth={true}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
			<Grid container item direction={"row"} spacing={2}>
				<Grid item xl={6}>
					<Tile content={(
						<>
						<Typography variant="h4" align="center" style={{ marginBottom: 20 }}>
							{budgetBasicData.selling_price && numberWithSpaces(budgetBasicData.selling_price)}
						</Typography>
						<Typography variant="h6" align="center">
							Sprzedaż całkowita
						</Typography>
						</>
					)} />
				</Grid>
				<Grid item xl={6}>
					<Tile content={(
						<>
						<Typography variant="h4" align="center" style={{ marginBottom: 20 }}>
							{budgetBasicData.costs_amount && numberWithSpaces(budgetBasicData.costs_amount)}
						</Typography>
						<Typography variant="h6" align="center">
							Koszty faktyczne
						</Typography>
						</>
					)} />
				</Grid>
			</Grid>
			<Grid item container direction={'row'} spacing={2}>
				<Grid item xs={4}>
					<Paper square style={{ padding: 20 }}>
						<Button
							variant="outlined"
							color="primary"
							fullWidth
							onClick={() => history.push(`/budzety/details/${budgetId}`)}
						>
							Plan budżetowy
						</Button>
					</Paper>
				</Grid>
				<Grid item xs={4}>
					<Paper square style={{ padding: 20 }}>
						<Button variant="outlined" color="primary" fullWidth onClick={() => history.push(`/faktury/${budgetId}/purchase`)}>
							Faktury zakupowe
						</Button>
					</Paper>
				</Grid>
							<Grid item xs={4}>
					<Paper square style={{ padding: 20 }}>
						<Button variant="outlined" color="primary" fullWidth onClick={() => history.push(`/faktury/${budgetId}/sales`)}>
							Faktury sprzedażowe
						</Button>
					</Paper>
				</Grid>
			</Grid>


			<Grid item>
				<Paper square style={{ height: 150, padding: 20 }}>
					<Typography variant="h5" align="center" style={{ marginBottom: 30 }}>
						Postępy prac
					</Typography>
					<ProgressBarCustom percent={(budgetBasicData.costs_amount/budgetBasicData.selling_price)*100} height={30} />
				</Paper>
			</Grid>

			{
				multiPlotValues.map(x => {
					return (
					<Grid item>
						<Paper square style={{ height: 120, padding: 20 }}>
							<Typography variant="h6" align="center" style={{ marginBottom: 30 }}>
								{x.name}
							</Typography>
							<ProgressBarCustom percent={(x.state.costs_amount / x.offer_data.selling_price)*100} height={20} />
						</Paper>
					</Grid>
		
					)
				})
			}
		</Grid>
	);
}

export default BudgetOverview;
